import React, { Component } from 'react';


export default class Null extends Component {
  
    render() {
  
      return (
        <></>
      )
    }
  }